import { EventPublishService } from '../../providers/event';
import { CommonService } from '../../providers/common';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { BookingService } from '../../providers/booking.service';
import { RoomsService } from '../../providers/rooms.service';

@Component({
  selector: 'app-add-booking',
  templateUrl: './add-booking.page.html',
  styleUrls: ['./add-booking.page.scss'],
})
export class AddBookingPage implements OnInit {
  bookingForm: any = FormGroup;
  submitted = false;
  roomData: any = [];
  rooms: any = [];
  checkoutDate: any;
  isSpinner = false;
  successResponse: any = [];
  minDate: any;
  checkoutMinDate: any;
  isBookingRequest = false;
  bookingDetails: any = {};
  roomAmount: any;

  constructor(
    private fb: FormBuilder,
    private navParams: NavParams,
    public roomService: RoomsService,
    private bookingService: BookingService,
    public modalCtrl: ModalController,
    private datePipe: DatePipe,
    private common: CommonService,
    private event: EventPublishService
  ) { }

  ngOnInit() {
    this.bookingForm = this.fb.group({
      email: [''],
      checkIn: ['', Validators.required],
      bookingDate: ['', Validators.required],
      checkOut: ['', Validators.required],
      name: ['', Validators.required],
      address: [''],
      phone: ['', Validators.required],
      whatsappNo: [''],
      // bookingStatus: REQUESTED,
      roomAmount: ['', Validators.required],
      discount: [''],
      paidAmount: [''],
      balanceAmount: [''],
      noOfAdults: [''],
      noOfKids: [''],
      // totalPeople: 0,
      guestPhoto: [''],
      idProof: [''],
      remarks: [''],
      peoples: this.fb.array([]),
      guestDetails: this.fb.array([]),
    });
    this.addNewAddressGroup();
    this.getRooms();

    if (this.navParams.get('room')) {
      this.roomData = this.navParams.get('room');
    }
    if (this.navParams.get('booking_request')) {
      this.isBookingRequest = this.navParams.get('booking_request');
      console.log(this.isBookingRequest);
    }
    if (this.navParams.get('booking')) {
      this.bookingDetails = this.navParams.get('booking');
      this.bookingForm = this.fb.group({
        email: [''],
        checkIn: ['', Validators.required],
        bookingDate: [''],
        checkOut: ['', Validators.required],
        name: ['', Validators.required],
        address: [''],
        phone: ['', Validators.required],
        whatsappNo: [''],
        // bookingStatus: REQUESTED,
        roomAmount: ['', Validators.required],
        discount: [''],
        paidAmount: [''],
        balanceAmount: [''],
        noOfAdults: [''],
        noOfKids: [''],
        // totalPeople: 0,
        guestPhoto: [''],
        idProof: [''],
        remarks: [''],
        peoples: this.fb.array([]),
        guestDetails: this.fb.array([]),
      });
    }
    if (this.navParams.get('checkInDate')) {
      console.log(this.navParams.get('checkInDate'));
      this.checkoutDate = this.navParams.get('checkInDate');
      let noOfNights = 1;
      if (this.navParams.get('noOfNights')) {
        noOfNights = +this.navParams.get('noOfNights');
      }
      this.minDate = this.datePipe.transform(Date(), 'yyyy-MM-dd');
      this.bookingForm
        .get('bookingDate')
        .patchValue(this.datePipe.transform(Date(), 'yyyy-MM-dd'));
      this.bookingForm
        .get('checkIn')
        .patchValue(
          this.datePipe.transform(
            this.navParams.get('checkInDate'),
            'yyyy-MM-dd'
          )
        );
      this.checkoutMinDate = this.datePipe.transform(
        new Date(
          this.checkoutDate.setDate(this.checkoutDate.getDate() + noOfNights)
        ),
        'yyyy-MM-dd'
      );
      this.bookingForm.get('checkOut').patchValue(this.checkoutMinDate);
      this.bookingForm.get('roomAmount').patchValue(this.roomData.price);
    }
    if (this.isBookingRequest) {
      console.log(this.roomData);
      this.bookingForm.get('name').patchValue(this.roomData.name);
      this.bookingForm.get('address').patchValue(this.roomData.address);
      this.bookingForm.get('phone').patchValue(this.roomData.phone);
      this.bookingForm.get('whatsappNo').patchValue(this.roomData.whatsappNo);
      this.bookingForm
        .get('roomAmount')
        .patchValue(this.roomData.roomId?.price);
      this.bookingForm.get('peoples').patchValue([
        {
          noOfAdults: this.roomData.noOfAdults,
          noOfKids: this.roomData.noOfKids,
        },
      ]);
    }
    this.roomAmount = this.roomData.price;
    // this.bookingForm.controls.roomAmount.disable();

    if (this.bookingDetails?._id) {
      this.bookingForm
        .get('checkIn')
        .patchValue(
          this.datePipe.transform(this.bookingDetails.checkIn, 'yyyy-MM-dd')
        );
      this.bookingForm
        .get('checkOut')
        .patchValue(
          this.datePipe.transform(this.bookingDetails.checkOut, 'yyyy-MM-dd')
        );
      this.bookingForm.get('name').patchValue(this.bookingDetails.name);
      this.bookingForm.get('address').patchValue(this.bookingDetails.address);
      this.bookingForm.get('phone').patchValue(this.bookingDetails.phone);
      this.bookingForm.get('email').patchValue(this.bookingDetails.email);
      this.bookingForm
        .get('whatsappNo')
        .patchValue(this.bookingDetails.whatsappNo);
      this.bookingForm
        .get('roomAmount')
        .patchValue(this.bookingDetails.roomAmount);
      this.bookingForm
        .get('noOfAdults')
        .patchValue(this.bookingDetails.noOfAdults);
      this.bookingForm.get('noOfKids').patchValue(this.bookingDetails.noOfKids);
      this.bookingForm.get('remarks').patchValue(this.bookingDetails.remarks);

      this.bookingForm.controls.roomAmount.disable();
      this.bookingForm.controls.discount.disable();

      this.addGuest();
    }
  }

  addGuest() {
    console.log("this.bookingDetails.noOfAdults", this.bookingDetails?.guestDetails)
    const add = this.bookingForm.get('guestDetails') as FormArray;
    for (let index = 0; index < this.bookingDetails.noOfAdults - 1; index++) {
      const name = this.bookingDetails?.guestDetails[index]?.name
        ? this.bookingDetails?.guestDetails[index]?.name
        : '';
      const mobile = this.bookingDetails?.guestDetails[index]?.mobile
        ? this.bookingDetails?.guestDetails[index]?.mobile
        : '';
      add.push(
        this.fb.group({
          name: [name],
          mobile: [mobile],
        })
      );
    }
  }

  onChangeDiscount(newDiscountValue: any) {
    console.log('newDiscountValue', this.roomAmount);
    const roomAmount =
      newDiscountValue > 0
        ? parseFloat(this.roomAmount) - parseFloat(newDiscountValue)
        : this.roomAmount;

    this.bookingForm.controls.roomAmount.setValue(roomAmount);
  }

  onChangeAdultsCount($event) {
    console.log("this.epf.guestDetails", this.epf.guestDetails)
    if (!($event?.target?.value > 0)) {
      return;
    }
    const diff = $event?.target?.value - this.epf.guestDetails?.length - 1;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        this.epf.guestDetails?.push(
          this.fb.group({
            name: [this.bookingDetails?.guestDetails[i]?.name ? this.bookingDetails?.guestDetails[i]?.name: ''],
            mobile: [this.bookingDetails?.guestDetails[i]?.mobile ?  this.bookingDetails?.guestDetails[i]?.mobile : ''],
          })
        );
      }
    } else if (diff < 0) {
      console.log(this.epf.guestDetails);
      for (let i = 0; i < diff * -1; i++) {
        this.epf.guestDetails?.removeAt(-1);
      }
    }
  }

  getRooms() {
    this.roomService.getRooms().subscribe((resp) => {
      if (resp.status == 'Success') {
        this.rooms = resp.data.lists;
        this.removeRooms();
      }
    });
  }

  addNewAddressGroup() {
    const add = this.bookingForm.get('peoples') as FormArray;
    add.push(
      this.fb.group({
        noOfAdults: [''],
        noOfKids: [''],
        roomAmount: [''],
        roomId: [''],
      })
    );
  }

  get epf() {
    return this.bookingForm.controls;
  }

  addBooking() {
    this.submitted = true;
    if (this.bookingForm.valid) {
      this.isSpinner = true;
      if (this.bookingDetails?._id) {
        const data = {
          name: this.epf.name.value,
          checkIn: this.epf.checkIn.value,
          checkOut: this.epf.checkOut.value,
          address: this.epf.address.value,
          phone: this.epf.phone.value,
          email: this.epf.email.value,
          whatsappNo: this.epf.whatsappNo.value,
          noOfAdults: this.epf.noOfAdults.value,
          noOfKids: this.epf.noOfKids.value,
          roomAmount: this.epf.roomAmount.value,
          discountAmount: parseInt(this.epf.discount.value),
          remarks: this.epf.remarks.value,
          guestDetails: JSON.stringify(
            this.bookingForm.get('guestDetails').value
          ),
          // bookingStatus: 'CHECKED_IN',
          // useKitchenSet: this.epf.useKitchenSet.value,
          // kitchenSetExpenseAmount: this.epf.kitchenSetExpenseAmount.value,
        };
        this.bookingService
          .updateBooking(data, this.bookingDetails._id)
          .subscribe(
            (resp) => {
              if (resp.status === 'Success') {
                this.modalCtrl.dismiss({
                  resp: true,
                });
              }
            },
            (error) => {
              this.isSpinner = false;
            }
          );
      } else {
        this.bookingForm.get('peoples').value.forEach((element, index) => {

          console.log("element",element)
          const data: any = {
            bookingDate: this.epf.bookingDate.value,
            checkIn: this.epf.checkIn.value,
            checkOut: this.epf.checkOut.value,
            email: this.epf.email.value,
            name: this.epf.name.value,
            address: this.epf.address.value,
            remarks: this.epf.remarks.value,
            phone: this.epf.phone.value,
            whatsappNo: this.epf.whatsappNo.value,
            roomId:
              index == 0 && !this.isBookingRequest
                ? this.roomData._id
                : index == 0 && this.isBookingRequest
                  ? this.roomData.roomId?._id
                  : element.roomId,
            noOfAdults: +element.noOfAdults,
            noOfKids: +element.noOfKids,
            roomAmount: index === 0 ? this.epf.roomAmount.value : element.roomAmount,
            // roomAmount: parseFloat(
            //   index == 0 ? this.epf.roomAmount.value : element.roomAmount
            // ),
            discountAmount: parseInt(this.epf.discount.value),
            totalPeople: +(element.noOfAdults + (element.noOfKids ?? 0)),
            bookingStatus: 'REQUESTED',
          };

          if (this.isBookingRequest) {
            data.bookingRequestId = this.roomData._id;
          }
          // console.log("this.bookingForm", data)
          // return
          this.bookingService.addBooking(data).subscribe(
            (resp) => {
              if (resp.status == 'Success') {
                this.submitted = false;
                this.isSpinner = false;
                this.successResponse = resp.data;
                this.checkoutDate.setDate(this.checkoutDate.getDate() - 1);
                this.common.presentToast('Successfully Saved');
                this.event.publishSomeData('booking');
                this.modalCtrl.dismiss({
                  resp: true,
                  checkinDate: this.epf.checkIn.value,
                  successResponse: this.successResponse,
                });
              }
            },
            (error) => {
              console.log(error);
              this.common.processError(error);
              this.submitted = false;
              this.isSpinner = false;
            }
          );
        });
      }
    }
  }

  closeModel() {
    this.modalCtrl.dismiss();
  }

  changeVilla($event, index) {
    const currentRow = this.rooms.filter((item) => {
      return item._id === $event?.detail?.value;
    });
    ((this.bookingForm.get('peoples') as FormArray).at(index) as FormGroup)
      .get('roomAmount')
      .setValue(currentRow[0]?.price);
  }

  changeVillaAmount($event, index) {
    console.log($event, index)
    if (index >= 0) {
      ((this.bookingForm.get('peoples') as FormArray).at(index) as FormGroup)
        .get('roomAmount')
        .setValue($event.detail.value);
    }
  }



  removeRooms() {
    this.rooms.forEach((element) => {
      if (element._id == this.roomData._id) {
        const index = this.rooms.findIndex((x) => x._id == this.roomData._id);
        this.rooms.splice(index, 1);
      }
      if (this.bookingForm.get('peoples').value?.length > 0) {
        this.bookingForm.get('peoples').value.forEach((element1) => {
          if (element._id == element1.roomId) {
            const i = this.rooms.findIndex((x) => x._id == element1.roomId);
            this.rooms.splice(i, 1);
          }
        });
      }
    });
  }

  // getCheckInDate(e){
  //   if(this.datePipe.transform(this.epf.checkIn.value,'dd MMM yyyy') >=  this.checkoutMinDate){
  //     this.checkoutMinDate=this.datePipe.transform(new Date(this.epf.checkIn.value.setDate(this.epf.checkIn.value.getDate() + 1)),'yyyy-MM-dd')
  //     this.bookingForm.get('checkOut').patchValue(this.checkoutMinDate);
  //   }
  // }
}
