import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private api:Api) { }

  getProductCategories(limit = 0, skip = 0) {
    let endpoint = 'products/categories/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this
      .api
      .get(endpoint);
  }
   
   addCategory(data){
    let endpoint='product/category/add'
    return this
    .api
    .post(endpoint,data)
  }

  editCategory(data, id) {
    let endpoint = 'product/category/' + id
    return this
      .api
      .put(endpoint, data)
  }

  deleteProductCategory(id){
    let endpoint='product/category/'+id
    return this
    .api
    .delete(endpoint)
  }

  getProducts(){
    let endpoint='products/view-all'
    return this
    .api
    .get(endpoint)
  }

  getProductList(limit = 0, skip = 0) {
    let endpoint = 'products/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this
      .api
      .get(endpoint);
  }

  addProductList(data){
    let endpoint='product/add'
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
    }
    return this
    .api
    .postAsFormDataImage(endpoint,fData)
  }

  editProductList(data, id) {
    let endpoint = 'product/product/' + id
    return this
      .api
      .put(endpoint, data)
  }

  deleteProductList(id){
    let endpoint='product/'+id
    return this
    .api
    .delete(endpoint)
  }
  
}




