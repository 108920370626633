import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  constructor(private api: Api) { }


  getTax(limit = 0, skip = 0) {
    let endpoint = 'tax/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this
      .api
      .get(endpoint);
  }

  addTax(data) {
    const endpoint = 'tax/add';
    return this.api.post(endpoint, data);
  }

  editTax(data, id) {
    const endpoint = 'tax/' + id;
    return this.api.put(endpoint, data);
  }

  deleteTax(id) {
    const endpoint = 'tax/' + id;
    return this.api.delete(endpoint);
  }

  getTaxView(id) {
    const endpoint = 'tax/view/' + id;
    return this.api.get(endpoint);
  }


  getRoomCount() {
    const endpoint = 'tax/count';
    return this.api.get(endpoint);
  }
}
