import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private api:Api) { }


  getFeedbackQuestions(limit = 0, skip = 0) {
    let endpoint = 'feedback/question/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this
      .api
      .get(endpoint);
  }

  getFeedbackList(limit = 0, skip = 0) {
    let endpoint = 'feedback/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this
      .api
      .get(endpoint);
  }

  getFeedback(id) {
    const endpoint = 'feedback/view/'+id;
    return this
      .api
      .get(endpoint);
  }

  addFeedbackQuestions(data){
    let endpoint='feedback/question/add'
    return this
    .api
    .post(endpoint,data)
  }

  deleteFeedbackQuestions(id){
    let endpoint='feedback/question/'+id
    return this
    .api
    .delete(endpoint)
  }

  addFeedback(data){
    let endpoint='feedback/add'
    return this
    .api
    .post(endpoint,data)
  }

  editFeedbackQuestions(data, id) {
    let endpoint = 'feedback/question/' + id
    return this
      .api
      .put(endpoint, data)
  }

}

