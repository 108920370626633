import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { CommonService } from '../../providers/common';
import { ProductService } from '../../providers/product.service';
import { RoomsService } from '../../providers/rooms.service';
import { TaxService } from '../../providers/tax.service';

@Component({
  selector: 'app-rooms-add',
  templateUrl: './rooms-add.page.html',
  styleUrls: ['./rooms-add.page.scss'],
})
export class RoomsAddPage implements OnInit {
  @Input() item: any;
  roomsAddForm: any = FormGroup;
  productList: any = [];
  taxList: any = [];
  roomImage: any;
  submitted = false;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private room: RoomsService,
    private tax: TaxService,
    private maodalCtrl: ModalController,
    private common: CommonService,
    private product: ProductService
  ) { }

  ngOnInit() {
    this.getTax();

    const name = this.item?.name ?? '';
    const description = this.item?.description ?? '';
    const hsnCode = this.item?.hsnCode ?? '';
    // const features = this.item?.features ?? null;
    const occupancy = this.item?.occupancy ?? '';
    const price = this.item?.price ?? '';
    const priority = this.item?.priority ?? 0;
    const taxss = this.item?.taxes ?? '';
console.log("taxss",taxss)
    const roomImage = this.item?.roomImage ?? '';
    const productManagement = this.item?.productManagement ?? '';
    this.roomsAddForm = this.fb.group({
      name: [name, Validators.required],
      description: [description],
      hsnCode: [hsnCode],
      // features: [features],
      occupancy: [occupancy],
      price: [price],
      priority: [priority],
      roomImage: [roomImage],
      productManagement: this.fb.array([]),
      selectedTax: this.fb.array([]),
      taxss: [taxss],
      taxes: this.fb.array([]),
      isActive: ['true'],
    });

    this.getProducts();
    const add = this.roomsAddForm.get('taxes') as FormArray;
    if (this.item?.taxes?.length > 0) {

      this.item?.taxes.map((tax) => {
        const selectedTaxesControl = this.roomsAddForm.get('selectedTax') as FormArray;
        selectedTaxesControl.push(this.fb.control(tax._id));

        const taxesControl = this.roomsAddForm.get('taxes') as FormArray;
        taxesControl.push(this.fb.control(tax));
      });

      // this.item?.taxes?.forEach((requestTax) => {
      //   add.push(
      //     this.fb.group({
      //       taxss: [requestTax]
      //     })
      //   );
      // });
    } else {
      this.addTax();
    }
    console.log(this.roomsAddForm);
  }

  get epf() {
    return this.roomsAddForm.controls;
  }

  getTax($event = null) {
    this.tax.getTax().subscribe({
      next: (resp: any) => {
        if (resp.status === 'Success') {
          this.taxList = resp.data.lists;
        }
      },
      error: (error: any) => {
        this.common.processError(error);
      },
    });
  }

  addRoomList() {
    this.submitted = true;
    if (this.roomsAddForm.valid) {
      console.log('roomsAddForm', this.roomsAddForm)
      console.log('adding', this.epf)
      const data: any = {
        name: this.epf.name.value,
        description: this.epf.description.value,
        // features:this.epf.features.value,
        hsnCode: this.epf.hsnCode.value,
        occupancy: this.epf.occupancy.value,
        price: this.epf.price.value,
        taxes: this.epf.selectedTax.value,
        // taxes: this.taxList.filter(tax => this.epf.selectedTax.value.includes(tax._id)),
        priority: this.epf.priority.value,
        file: this.roomImage,
        productManagement: this.item?._id
          ? this.roomsAddForm.get('productManagement').value
          : null, //JSON.stringify(this.roomsAddForm.get('productManagement').value),
        isActive: true,
      };
      // return
      // if(this.epf.kitchenSetAmount.value!==''){
      //   data.kitchenSetAmount=this.epf.kitchenSetAmount.value;
      // }

      this.isLoading = true;
      if (this.item?._id) {
        console.log('Edit', data);
        this.room.editRooms(data, this.item?._id).subscribe({
          next: (resp: any) => {
            if (resp.status === 'Success') {
              this.maodalCtrl.dismiss({
                response: true,
                data: resp?.data,
              });
            }
          },
          error: (error: any) => {
            this.common.processError(error);
            this.isLoading = false;
          },
        });
      } else {
        this.room.addRooms(data).subscribe({
          next: (resp: any) => {
            if (resp.status === 'Success') {
              this.maodalCtrl.dismiss({
                response: true,
                data: resp?.data,
              });
            }
          },
          error: (error: any) => {
            this.common.processError(error);
            this.isLoading = false;
          },
        });
      }
    }
  }

  addProduct() {
    const add = this.roomsAddForm.get('productManagement') as FormArray;
    add.push(
      this.fb.group({
        quantity: [''],
        product: [''],
      })
    );
  }

  addTax() {
    const add = this.roomsAddForm.get('taxes') as FormArray;
    add.push(
      this.fb.group({
        taxss: [''],
      })
    );
  }

  changeTax(event: any, index: number) {
    const selectedTaxArray = this.roomsAddForm.get('selectedTax') as FormArray;
    const selectedControl = selectedTaxArray.at(index);
    if (selectedControl) {
      selectedControl.setValue(event.detail.value);
    } else {
      selectedTaxArray.push(this.fb.control(event.detail.value));
    }
  }

  fileChangeEvent2(event: any): void {
    this.roomImage = event.target.files[0];
    console.log("event", this.roomImage)
    if (event.target.files.length === 0) return;
    for (let i = 0; i < event.target.files.length; i++) {
      let image = event.target.files[i];
      var data = {
        room: this.item._id,
        file: image,
      };
      console.log("data: " + data)
      this.room.updateFeatureImage(data).subscribe((resp) => {
        if (resp.status == 'Success') {
          this.item.images.push(resp.data);
        }
      });
    }
  }

  getProducts() {
    this.product.getProducts().subscribe((resp) => {
      if (resp.status == 'Success') {
        this.productList = resp.data.lists;

        if (this.item?.productManagement?.length > 0) {
          const add = this.roomsAddForm.get('productManagement') as FormArray;
          for (
            let index = 0;
            index < this.item?.productManagement?.length;
            index++
          ) {
            const product = this.item?.productManagement[index]?.product?._id
              ? this.item?.productManagement[index]?.product?._id
              : '';
            const quantity = this.item?.productManagement[index]?.quantity
              ? this.item?.productManagement[index]?.quantity
              : '';
            add.push(
              this.fb.group({
                product: [product],
                quantity: [quantity],
              })
            );
          }
        } else {
          // this.addProduct()
        }
      }
    });
  }

  closeModel() {
    this.maodalCtrl.dismiss();
  }

  getValue(e) {
    this.roomsAddForm.get('isActive').patchValue(e.target.checked);
  }
}
