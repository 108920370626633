import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  constructor(private api: Api) { }


  getExpense(limit = 0, skip = 0) {
    let endpoint = 'expense/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this.api.get(endpoint);
  }

  addExpense(data) {
    const endpoint = 'expense/add';
    return this.api.post(endpoint, data);
  }

  editExpense(data, id) {
    const endpoint = 'expense/' + id;
    return this.api.put(endpoint, data);
  }

  deleteExpense(id) {
    const endpoint = 'expense/' + id;
    return this.api.delete(endpoint);
  }

  getExpenseView(id) {
    const endpoint = 'expense/view/' + id;
    return this.api.get(endpoint);
  }

  getExpenseFood() {
    const endpoint = 'expense/view-category/food';
    return this.api.get(endpoint);
  }

  getExpenseOther() {
    const endpoint = 'expense/view-category/other';
    return this.api.get(endpoint);
  }

  getExpenseCategory(id) {
    const endpoint = 'bookings/expense/view-category/' + id;
    return this.api.get(endpoint);
  }

  getExpenseCount() {
    const endpoint = 'expense/count';
    return this.api.get(endpoint);
  }
}

