import { Injectable } from '@angular/core';
import { Api } from './api';

@Injectable({
  providedIn: 'root',
})
export class RoomsService {
  constructor(private api: Api) {}

  getRooms(limit = 0, skip = 0) {
    let endpoint = 'rooms/view-all?';
    if (skip > 0) {
      endpoint += 'skip=' + skip + '&';
    }
    if (limit > 0) {
      endpoint += 'limit=' + limit;
    }
    return this.api.get(endpoint);
  }

  addRooms(data) {
    let endpoint = 'room/add';
    let fData = new FormData();
    for (var key in data) {
      console.log('key', key);
      if (key !== 'taxes') {
        // fData.append(key[], data[key]);
        fData.append(key, data[key]);
      }
    }
    if (data.taxes) {
      data.taxes.forEach((tax) => {
        if (data.taxes) {
          fData.append('taxes[]', tax);
        }
      });
    }

    // return;
    return this.api.postAsFormDataImage(endpoint, fData);
  }

  editRooms(data, id) {
    let endpoint = 'room/room/' + id;
    return this.api.put(endpoint, data);
  }

  deleteRooms(id) {
    let endpoint = 'room/' + id;
    return this.api.delete(endpoint);
  }

  getRoomView(id) {
    let endpoint = 'rooms/view/' + id;
    return this.api.get(endpoint);
  }

  updateFeatureImage(data) {
    let endpoint = 'room-image/feature-update';
    let fData = new FormData();
    for (var key in data) {
      fData.append(key, data[key]);
    }
    return this.api.postAsFormDataImage(endpoint, fData);
  }

  addMultipleImage(data) {
    let endpoint = 'room-image/add';
    let fData = new FormData();
    for (var key in data) {
      fData.append(key, data[key]);
    }
    return this.api.postAsFormDataImage(endpoint, fData);
  }

  deleteImage(id) {
    let endpoint = 'room-image/image/' + id;
    return this.api.delete(endpoint);
  }

  getRoomCount() {
    let endpoint = 'rooms/count';
    return this.api.get(endpoint);
  }
}
