import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { CommonService } from '../../providers/common';
import { FeedbackService } from '../../providers/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss'],
})
export class FeedbackPage implements OnInit {

  questions: any = []
  customForm: any = FormGroup
  rating: any;
  comment: any;
  bookingDetails: any;
  viewFeedback = false;

  constructor(private feedback: FeedbackService,
    private common: CommonService,
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    if (this.navParams.get('feedbackId')) {
      this.bookingDetails = {}
      this.feedback.getFeedback(this.navParams.get('feedbackId')).subscribe(resp => {
        if (resp.status === 'Success') {
          this.bookingDetails=resp.data.bookingId;
          this.bookingDetails.feedback = resp.data;
        }
      })
      this.viewFeedback = true;
    } else if (this.navParams.get("booking")) {
      this.bookingDetails = this.navParams.get("booking")
      this.viewFeedback = this.bookingDetails.feedback?._id ? true : false;
    }
  }

  getAnswer(id) {
    if (this.bookingDetails?.feedback && this.bookingDetails?.feedback?.answers?.length > 0) {
      const curQues = this.bookingDetails?.feedback?.answers?.filter(x => (x.question === id || x.question?._id === id));
      return curQues[0]?.answer;
    }
    return '';
  }

  ngOnInit() {
    this.getQuestionsFeedback()
  }

  closeModel() {
    this.modalCtrl.dismiss()
  }

  openWhatsApp() {
    window.open('whatsapp://send?phone=' + (this.bookingDetails.whatsappNo?.length === 10 ? ('91' + this.bookingDetails.whatsappNo) : this.bookingDetails.whatsappNo) + '&text=Thank you for your valuable feedback. We hope you will have a repeated stay with us as we look forward to welcoming you again to the Pinnacle Resorts in the near future.\n"Team Management”');

  }


  getQuestionsFeedback() {
    this.feedback.getFeedbackQuestions().subscribe(resp => {
      if (resp.status == 'Success') {
        this.questions = resp.data.lists
        this.questions.forEach(element => {
          element.answer = null
        });
      }
    })
  }


  submiFeedback() {
    // if (!this.comment) {
    //   this.common.presentToast("Please enter comment")
    //   return;
    // }
    let feedbackArray: any = {}
    let answers: any = []
    let answeredAll = true;
    this.questions.forEach(element => {
      if (element.answer != null) {
        var data = {
          question: element._id,
          answer: element.answer
        }
        answers.push(data)
      } else {
        answeredAll = false
        return;
      }
    });
    if (!answeredAll) {
      this.common.presentToast("Please answer all questions");
      return;
    }
    feedbackArray.questions = answers
    feedbackArray.customer = this.bookingDetails.customer
    feedbackArray.roomId = this.bookingDetails.roomId._id
    feedbackArray.comment = this.comment
    feedbackArray.bookingId = this.bookingDetails._id
    console.log(feedbackArray)
    this.feedback.addFeedback(feedbackArray).subscribe(resp => {
      if (resp.status == 'Success') {
        this.common.presentToast("Successfully submitted")
        this.modalCtrl.dismiss()
      }
    }, error => {
      console.log(error);
      this.common.processError(error);
    })

  }

}
